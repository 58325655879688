<template>
  <div class="content">
    <div class="member-search">
      <div class="search-form" @keyup.enter="handleSearch">
        <Form :label-width="90" :model="searchData" ref="formValidate">
          <Row :gutter="16">
            <template v-if="!branchShow">
              <Col span="6">
                <FormItem label="省份">
                  <Select
                    clearable
                    v-model="searchData.pid"
                    placeholder="请选择"
                    @on-change="changePro"
                  >
                    <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="城市">
                  <Select
                    clearable
                    v-model="searchData.cid"
                    placeholder="请选择"
                    @on-change="changeCity"
                    @click.native="changeBossCity"
                  >
                    <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="门店">
                  <Select
                    clearable
                    v-model="searchData.sid"
                    placeholder="请选择"
                    @on-change="changeStore"
                    @click.native="changeBossBranch"
                  >
                    <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
            </template>
            <template v-else>
              <Col span="6">
                <FormItem label="省份">
                  <Input disabled v-model="proName"></Input>
                </FormItem>
              </Col>
              <Col span="5">
                <FormItem label="城市">
                  <Input disabled v-model="cityName"></Input>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="门店">
                  <Input disabled v-model="sName"></Input>
                </FormItem>
              </Col>
            </template>
            <Col span="6">
              <FormItem label="会员">
                <Select
                  clearable
                  filterable
                  placeholder="请选择"
                  v-model="searchData.member_id"
                >
                  <Option
                    v-for="item in userList"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.true_name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="6">
              <FormItem label="促进类型">
                <Select clearable v-model="searchData.cjlx" placeholder="请选择">
                  <Option value="问题促进">问题促进</Option>
                  <Option value="预警促进">预警促进</Option>
                  <Option value="预约促进">预约促进</Option>
                  <Option value="数据促进">数据促进</Option>
                  <Option value="生日促进">生日促进</Option>
                  <Option value="其他促进">其他促进</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="促进时间">
                <Date-picker
                  @on-change="handleChangeDate"
                  type="daterange"
                  placeholder="选择日期"
                  style="width:100%;"
                ></Date-picker>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="促进师">
                <Select
                  clearable
                  filterable
                  placeholder="请选择"
                  v-model="searchData.staff_id"
                >
                  <Option
                    v-for="item in staffList"
                    :value="item.staff_id"
                    :key="item.staff_id"
                  >{{ item.staff__name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="会员姓名">
                <Input v-model="searchData.member" placeholder="请输入" clearable></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="6">
              <FormItem label="促进记录">
                <Input v-model="searchData.cjjl" placeholder="请输入" clearable />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col :lg="{ span: 12, offset: 12 }" :md="{ span: 12, offset: 12 }">
              <Button type="warning" @click="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
    <div class="content-panel">
      <h2 class="content-title border-bottom"
        <span>促进记录</span>
        <div class="member-oprate">
          <a href="javascript:;" class="export" @click="exportData()">导出</a>
        </div>
      </h2>
      <div class="content-body">
          <Table :columns="columns" :data="resultData"></Table>
          <Row>
            <Col span="12">
              <div class="table-oprate"></div>
            </Col>
            <Col span="12">
              <span class="records">共 {{ pageTotal }} 条记录</span>
            </Col>
          </Row>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="pageTotal"
                :current="searchData.page"
                :page-size="searchData.size"
                @on-change="handleChangePage"
              ></Page>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import memberService from '@/services/memberService'
import boostService from '@/services/boostService'
import comparisonService from '@/services/comparisonService'

export default {
  data() {
    return {
      searchData: {
        member: '',
        member_id: '',
        staff_id: '',
        page: 1,
        size: 10,
        cjlx: '',
        cjjl: '',
        pid: '',
        cid: '',
        sid: ''
      },
      isAdmin: '',
      staffList: [],
      proList: [],
      cityList: [],
      storeList: [],
      userList: [],
      columns: [
        { title: '时间', key: 'sj', align: 'center', width: 120 },
        { title: '所属分院', key: 'md', align: 'center' },
        { title: '会员姓名', key: 'name', align: 'center' },
        { title: '家庭关系', key: 'jtgx', align: 'center' },
        { title: '会员卡系', key: 'kx', align: 'center'  },
        { title: '会员手机号', key: 'mobile', align: 'center' },
        { title: '会员状态', key: 'hyzt', align: 'center' },
        { title: '促进师', key: 'cjs', align: 'center' },
        { title: '促进类别', key: 'cjlx', align: 'center' },
        { title: '促进内容', key: 'cjnr', align: 'center' },
        { title: '促进方式', key: 'cjfs', align: 'center' },
        { title: '处理记录', key: 'cljl', align: 'center' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const self = this
            return h('div', [
              h('Button', {
                attrs: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click() {
                    self.handleDelete(params.row.id);
                  }
                }
              }, '删除')
            ])
          }
        },
      ],
      resultData: [],
      pageTotal: 0,
      branchShow: false,
      branch_id: [],
      power: [],
      proName: '',
      cityName: '',
      sName: '',
    }
  },
  methods: {
    // 根据省份选择城市
    changePro() {
      this.cityList = this.city[this.searchData.pid]
      this.searchData.cid = ''
    },
    // 根据城市选择门店
    changeCity() {
      this.storeList = this.store[this.searchData.cid]
      this.searchData.sid = ''
    },
    // 根据门店选择用户
    changeStore() {
      if (this.searchData.sid) {
        const params = {
          page: 1,
          size: 1000,
          pid: this.searchData.pid,
          cid: this.searchData.cid,
          sid: this.searchData.sid
        }
        comparisonService.getUserList(params).then((data) => {
          this.userList = data.list
        })
      } else {
        this.userList = []
      }
    },
    initData() {
      let list = JSON.parse(localStorage.getItem('userInfo'))
      memberService.getBase().then((data) => {
        this.proList = data.branch.pro
        this.city = data.branch.city
        this.store = data.branch.store
        this.power = list.user_info.power
        this.memberTypeData = data.member_type
        if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
          this.branchShow = false
        } else {
          if (this.power.indexOf('服务管理--查看全国会员信息权限') === -1 && this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1) {
            this.branchShow = true
            this.branch_id = list.user_info.branch
            this.searchData.sid = this.branch_id[2]
            this.searchData.cid = this.branch_id[1]
            this.searchData.pid = this.branch_id[0]
            this.proName = list.user_info.pro_name
            this.cityName = list.user_info.city_name
            this.sName = list.user_info.branch_name
          }
        }
      })
    },
    getQuery() {
      boostService.promotionRecords(this.searchData).then(data => {
        this.resultData = data.list
        this.pageTotal = data.row_size
      })
    },
    handleSearch() {
      this.searchData.page = 1
      this.getQuery()
    },
    exportData () {
      location.href = 'http://conlife.sskh.net:9928/api/cj/menu/export_cjjl?start=' + this.searchData.start +
      '&end=' + this.searchData.end +
      '&member=' + this.searchData.member +
      '&member_id=' + this.searchData.member_id +
      '&cjjl=' + this.searchData.cjjl +
      '&cjlx=' + this.searchData.cjlx +
      '&pid=' + this.searchData.pid +
      '&cid=' + this.searchData.cid +
      '&sid=' + this.searchData.sid +
      '&staff_id=' + this.searchData.staff_id
    },
    handleDelete(id) {
      if (!this.isAdmin) {
        this.$Message.warning('你没有删除权限,请联系管理员')
        return
      }
      this.$Modal.confirm({
        title: '确定删除所选记录吗？',
        onOk: () => {
          boostService.promotionDelete({ jl_id: id }).then(() => {
            this.getQuery()
          })
        }
      })
    },
    // 促进日期
    handleChangeDate(date) {
      this.searchData.start = date[0]
      this.searchData.end = date[1]
    },
    // 页码
    handleChangePage(page) {
      this.searchData.page = page
      this.getQuery()
    },
    // 点击门店先选择城市
    changeBossBranch() {
      if (!this.searchData.cid) {
        this.$Message.warning('请先选择城市')
      }
    },
    // 点击城市先选择省份
    changeBossCity() {
      if (!this.searchData.pid) {
        this.$Message.warning('请先选择省份')
      }
    },
    getStaffs() {
      boostService.promotionStaffs().then((data) => {
        this.staffList = data
      })
    }
  },
  mounted() {
    this.initData()
    this.getQuery()
    this.getStaffs()
    this.power = localStorage.getItem('userPower')
    if (this.power.indexOf('服务评价人') != -1) {
        this.isAdmin = '1'
    }

  },
}
</script>

<style lang="less" scoped>
.member-search {
  background-color: #fff;
  margin-bottom: 20px;
}
.search-form {
  padding: 20px 15px;
}
.content-panel {
  background-color: #fff;
}
.member-oprate {
  float: right;
  font-size: 14px;
}

.member-oprate .export {
  background: url(@/assets/img/icon_export.png) no-repeat;
}
</style>
